<template>
    <div class="">

      <!-- 404 Error Start -->
      <section id="error-404" class="padding">
        <div class="container text-center">
          <div class="row">

            <div class="col-xs-12">
              <div class="error-image margin_bottom">
                <img src="/assets/images/404-other.webp" alt="404" class="img-responsive"/>
              </div>
            </div>

            <div class="col-xs-12">
              <div class="error-text">
                <h1 class="bold-text">{{ $t('app.opps') }}</h1>
                <h3>{{ $t('app.looks_something_wrong') }}</h3>
                <p>{{ $t('app.page_not_exists') }}</p>
                <div class="erro-button">
                  <router-link v-if="$i18n.locale == 'en'" to="/" class="btn-blue">{{ $t('app.go_back_to_home') }}</router-link>
                  <router-link v-if="$i18n.locale == 'ar'" to="/?lang=ar" class="btn-blue">{{ $t('app.go_back_to_home') }}</router-link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <!-- 404 Error End -->

    </div>
</template>


<script>
    export default {
        name: 'NotFound',
        components: {},
        data(){
            return {
                locale: 'en',

                // Meta
                meta: {
                    pgtitle: '404 Page Not Found',
                    description: 'description..',
                    keywords: 'keywords...',
                    title: 'title...',
                  },
            }
        },
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { name: 'title', content: this.meta.title }   
                ]
            }
        },
        mounted() {},
        created() {

          //
          if(localStorage.getItem('locale')) {
            this.locale = localStorage.getItem('locale');
          }

        },
        methods: {
          
          //
          
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
